import React from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import { RichText } from '../components/Shared/RichText/RichText'
import { Dropdown } from '../components/Dropdown/DropDown'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import SectionFaq from '../components/SectionFaq/SectionFaq'
import Breadcrum from '../components/Breadcrum/Breadcrum'

const Faq = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom,
        allContentfulPageCustom: {
            nodes
        }
    }
  }) => {
    const lang = useUrl()
    const{heroImage , faqs, slug, seoTitle, seoDescription, pageTitle, breadcrum} = contentfulPageCustom
  return (
    <section id = 'faq'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
      {breadcrum && <Breadcrum data ={breadcrum} title = 'faq'/>}
      <HeroComponent data = {heroImage}/>
      <SectionFaq data = {faqs} />
    </section>
  )
}

export const PagePageContactQuery = graphql`
    query pagePageContact($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            breadcrum {
              title
              slug
            }
            pageTitle
            node_locale
            description{
              raw
            }
            slug
            heroImage {
              title
              heroImage {
                gatsbyImageData
                title
              }
              heroImageMobile {
                gatsbyImageData
                title
              }
            }
            faqs {
                title
                description {
                  raw
                }
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default Faq