import React from 'react'
import { Dropdown } from '../Dropdown/DropDown'

const SectionFaq = ({data, regen}) => {
  return (
    <div className={`container ${regen ? 'regen-bg' : ""}`}>
        <div className='container-lg'>
            <div className='faq'>
                {data.map((faq:any, id:number) =>{
                    return(
                    <div key={id}> <Dropdown data = {faq} />  </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default SectionFaq