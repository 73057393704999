import React, { useState } from "react"
import * as styles from "./dropdown.module.scss"
import { RichText } from "../Shared/RichText/RichText"
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
export const Dropdown = ({ data }) => {
    const {title, description, descriptionColor, titleColor} = data
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <div className={`${styles.dropdown}`} style={{borderBottom: titleColor === '#ffffff' ? '1px solid #ffffff' : '1px solid #ff5005'}}>
                <div className={`${styles.header} `} style={{color: titleColor}} onClick={() => setExpanded(!expanded)}>
                    <h3>{title}</h3>
                    { expanded ? <span className= {styles.active}><AiOutlineMinus /></span> : <span className={styles.hide}><AiOutlinePlus /></span>}
                </div>
                {expanded && (
                    <div className={styles.desc} style= {{color: descriptionColor}}> <RichText content={description} /></div>
                )}
            </div>
        </>
    )
}
